import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import { Odin } from '../src/components/Odin/Odin';
import Heimdall from '../src/components/Heimdall/Heimdall';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { Freya } from '../src/components/Freya/Freya';
import Buttons from '../src/components/Buttons/Buttons';
import Hod from '../src/components/Hod/Hod';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';

import './agencia_viajes.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query viajes($locale: String!) {
    allAgenciaViajesSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allAgenciaViajesTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allAgenciaViajesImageBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allAgenciaViajesMobileImageBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allAgenciaViajesBreadcrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allAgenciaViajesPromotionalBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          cta_card
          ctaText_card
          title_card
          text_left
          title_left
          additional_text
          text_right
          book_button
        }
      }
    }
    allAgenciaViajesRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class AgenciaViajes extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allAgenciaViajesTitleBlock.edges[0].node.title,
      image: {
        url: this.props.data.allAgenciaViajesImageBlock.edges[0].node.localImage.childImageSharp
          .fluid,
        alt: this.props.data.allAgenciaViajesImageBlock.edges[0].node.alt,
      },
      imageMobile: {
        url: this.props.data.allAgenciaViajesMobileImageBlock.edges[0].node.localImage
          .childImageSharp.fluid,
        alt: this.props.data.allAgenciaViajesMobileImageBlock.edges[0].node.alt,
      },
    };

    const odinData = {
      image:
        'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/015/022/original/hotel-agencia-1200x600.jpg',
      alt: 'agencias-de-viajes',
      title: this.props.data.allAgenciaViajesPromotionalBlock.edges[0].node.title_card,
      buttons: {
        cta1: this.props.data.allAgenciaViajesPromotionalBlock.edges[0].node.cta_card,
        ctaText1: this.props.data.allAgenciaViajesPromotionalBlock.edges[0].node.ctaText_card,
        size: 'alone',
        color1: 'orange',
      },
    };

    const h2Data = {
      title: this.props.data.allAgenciaViajesTitleBlock.edges[0].node.description,
    };

    const contentData = {
      text_left: this.props.data.allAgenciaViajesPromotionalBlock.edges[0].node.text_left,
      title: this.props.data.allAgenciaViajesPromotionalBlock.edges[0].node.title_left,
      additional_text:
        this.props.data.allAgenciaViajesPromotionalBlock.edges[0].node.additional_text,
      text_right: this.props.data.allAgenciaViajesPromotionalBlock.edges[0].node.text_right,
    };

    const PrepareFreya = (src) => ({
      isSlider: true,
      cards: src.edges
        .map((e) => ({
          image: e.node.localImage.childImageSharp.fluid,
          altImage: e.node.image_alt,
          imgsize: 'promoted',
          title: e.node.title,
          description: e.node.description,
          buttons: {
            size: 'alone',
            color: 'white',
            ctaText: e.node.ctaText,
            popup: {
              enable: true,
              textpop: e.node.popup_title + e.node.popup_content,
            },
          },
        }))
        .filter((e) => e.image !== undefined),
    });

    const buttons = {
      cta: this.props.data.allAgenciaViajesPromotionalBlock.edges[0].node.book_button,
      size: 'alone',
      color: 'white',
      ctaText: tt('RESERVAR *', this.props.pageContext.locale),
    };

    const isDesktopButton = (
      <Buttons
        link={buttons.cta}
        size={buttons.size}
        color={buttons.color}
        text={buttons.ctaText}
      />
    );

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
      >
        <SEO
          title={this.props.data.allAgenciaViajesSeoBlock.edges[0].node._0.title}
          description={this.props.data.allAgenciaViajesSeoBlock.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allAgenciaViajesRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allAgenciaViajesImageBlock.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="agencia-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allAgenciaViajesBreadcrumbBlock.edges[0].node.name}
              />
              <H2 data={h2Data} />
              <Odin data={odinData} locale={this.props.pageContext.locale} />
              <div className="main-container">
                <div className="left-container">
                  <H3 data={contentData} />
                  <div dangerouslySetInnerHTML={{ __html: contentData.text_left }} />
                  {/* {isDesktopButton} */}
                </div>
                <div className="right-container">
                  <div className="standard-box">
                    <div dangerouslySetInnerHTML={{ __html: contentData.text_right }} />
                  </div>
                </div>
              </div>
              <div
                className="additional-content"
                dangerouslySetInnerHTML={{ __html: contentData.additional_text }}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default AgenciaViajes;
